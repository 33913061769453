import { PortSearchCriteria } from "domain/entity/Port/PortSearchCriteria";
import { PortConstant } from "presentation/constant/Port/PortConstant";
import { usePortVM } from "presentation/hook/Port/usePortVM";
import { usePortTracked } from "presentation/store/Port/PortProvider";
import { useCallback } from "react";
import { HPHButton, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar, SidebarActionCross, SidebarTitle, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import PortSearchForm from "./PortSearchForm";

const PortSearchPanel = () => {
    const [portState] = usePortTracked();
    const portVM = usePortVM();
    const {isLoading} = portState;

    const resetButtonClicked = useCallback(() => {
        portVM.onSearchCriteriaResetClick();
    }, [portVM]);
    
    const searchButtonClicked = useCallback((searchCriteria:PortSearchCriteria) => {
        portVM.onShowLoading();
        portVM.searchPortList(searchCriteria).then((data) => {
            portVM.onHideLoading();
        }).catch(error => {
            portVM.onHideLoading();
        })
        
    }, [portVM])

    return (
        <div className='side-form-content-left-wrapper' style={{padding:"20px 20px 20px 0px"}}>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{PortConstant.SEARCH_CRITERIA}</SidebarTitle>
                        <SidebarActionCross>
                            <IconButton
                                fileName="Icon-cross" size="medium" toolTipText='Close' toolTipArrow={false} onClick={portVM.onSearchClick} />
                        </SidebarActionCross>
                    </Sidebarheader>                
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium" /> }

            <div className={'add-edit-form'} style={{ maxHeight:'75vh' , height:'75vh', overflow: 'auto'}}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        
                        {/* Stack Form Start */}
                        <PortSearchForm/>
                    </div>                  
                </div>
            </div>
            <SidebarActionBar>
                <HPHButton label={'Reset'} size={'Small'} theme={'Secondary'} onClick={resetButtonClicked} />
                <HPHButton label={'Search'} size={'Small'} theme={'Primary'} onClick={() => searchButtonClicked(portState.searchCriteria)} />
            </SidebarActionBar>
        </div>
    );
}

export default PortSearchPanel;
