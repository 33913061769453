import { PortConstant, portRequiredFieldList } from "presentation/constant/Port/PortConstant";
import { usePortVM } from "presentation/hook/Port/usePortVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { usePortTracked } from "presentation/store/Port/PortProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DatePickerComponent from "presentation/view/components/DatePickerComponent";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { memo, useCallback, useMemo, useState } from "react";
import { FieldType, HPHBreadcrumb, HPHCheckbox, IconButton, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, SidebarCaption, Sidebarheader, SidebarTitle, StyledAction, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const PortEditPanel = () => {
    const PORT_CONSTANT= PortConstant.Table;
    const [isLoading, setIsLoading] = useState(false);
    const [portState] = usePortTracked();
    const portVM = usePortVM();
    const {currentEditRow,isAdd,isEdit,searchCriteria} = portState;
    const messageBarVM = useMessageBarVM();
    

    const handleSave = useCallback(async () => {
        if(!currentEditRow.portCode){
            messageBarVM.showError("Port Code is mandatory.");
            return;
        }
        if(!currentEditRow.portName){
            messageBarVM.showError("Port Name is mandatory.");
            return;
        }
        if(!currentEditRow.effectiveDate){
            messageBarVM.showError("Effective Date is mandatory.");
            return;
        }
        if(!currentEditRow.countryCode){
            messageBarVM.showError("Country Code is mandatory.");
            return;
        }
        if(!currentEditRow.geogInd){
            messageBarVM.showError("Geog Ind is mandatory.");
            return;
        }
        if(!currentEditRow.activeInd){
            messageBarVM.showError("Active Ind is mandatory.");
            return;
        }
        setIsLoading(true);
        const data = await portVM.onSave(currentEditRow);
        if(data && data.success){
            messageBarVM.showSuccess("Save Successfully.");
            portVM.searchPortList(searchCriteria).then((data) => {            
                setIsLoading(false)
            }).catch(error => {            
                setIsLoading(false)
            });
        }else{
            setIsLoading(false)
            messageBarVM.showError(data.data);
            return;
        }
    }, [currentEditRow, portVM, messageBarVM, searchCriteria]);

    const handClose = useCallback(() => {
        portVM.onCloseEidtPanel();
    }, [portVM]);

    const memoProvinceCodeOptions = useMemo(() => {
        const provinceCodeOptions = currentEditRow.countryCode?portState.dynamicOptions.provinceCodeDropdownOptions[currentEditRow.countryCode]:[];
        return provinceCodeOptions;
    }, [currentEditRow.countryCode, portState.dynamicOptions.provinceCodeDropdownOptions])
    
    
    const memoPortCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd)}
                isShowOptional={false}
                readOnlyValue={currentEditRow?.portCode || ''}
                fieldValue={currentEditRow?.portCode}
                fieldLabel={PORT_CONSTANT.PORT_CODE}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'portCode'}
                maxLength={60}
                sort={false}
                isTextFieldCapitalize={true}
                requiredFieldList={portRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    portVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [isAdd, currentEditRow?.portCode, PORT_CONSTANT.PORT_CODE, portVM])
    
    
    const memoPortName = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd||isEdit)}
                isShowOptional={false}
                readOnlyValue={currentEditRow?.portName || ''}
                fieldValue={currentEditRow?.portName}
                fieldLabel={PORT_CONSTANT.PORT_NAME}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'portName'}
                maxLength={60}
                sort={false}
                requiredFieldList={portRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    portVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [isAdd, isEdit, currentEditRow?.portName, PORT_CONSTANT.PORT_NAME, portVM])
    
    const memoPortEncodeScheme = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd||isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.portEncodeScheme || ''}
                fieldValue={currentEditRow?.portEncodeScheme}
                fieldLabel={PORT_CONSTANT.PORT_ENCODE_SCHEME}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'portEncodeScheme'}
                maxLength={60}
                sort={false}
                requiredFieldList={portRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    portVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [isAdd, isEdit, currentEditRow?.portEncodeScheme, PORT_CONSTANT.PORT_ENCODE_SCHEME, portVM])
    
        const memoCountryCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd||isEdit)}
                isShowOptional={false}
                readOnlyValue={currentEditRow?.countryCode || ''}
                fieldValue={currentEditRow?.countryCode}
                fieldLabel={PORT_CONSTANT.COUNTRY_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'countryCode'}
                maxLength={60}
                sort={false}
                options={portState.dynamicOptions.countryCodeDropdownOptions}
                requiredFieldList={portRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    portVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [isAdd, isEdit, currentEditRow?.countryCode, PORT_CONSTANT.COUNTRY_CODE, portState.dynamicOptions.countryCodeDropdownOptions, portVM])
    
        const memoProvinceCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd||isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.provinceCode || ''}
                fieldValue={currentEditRow?.provinceCode}
                fieldLabel={PORT_CONSTANT.PROVINCE_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'provinceCode'}
                maxLength={60}
                sort={false}
                options={memoProvinceCodeOptions}
                requiredFieldList={portRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    portVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [isAdd, isEdit, currentEditRow?.provinceCode, PORT_CONSTANT.PROVINCE_CODE, memoProvinceCodeOptions, portVM])
    
        const memoGeographicalInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd||isEdit)}
                isShowOptional={false}
                readOnlyValue={currentEditRow?.geogInd || ''}
                fieldValue={currentEditRow?.geogInd}
                fieldLabel={PORT_CONSTANT.GEOG_IND}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'geogInd'}
                maxLength={60}
                sort={false}
                options={portState.dynamicOptions.geographicalIndDropdownOptions}
                requiredFieldList={portRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    portVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [isAdd, isEdit, currentEditRow?.geogInd, PORT_CONSTANT.GEOG_IND, portState.dynamicOptions.geographicalIndDropdownOptions, portVM])
        
        
        const memoEffectiveDate = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
                <DatePickerComponent
                        disabled={!(isAdd||isEdit)}
                        label={PORT_CONSTANT.EFFECTIVE_DATE}
                        width="260px"
                        optional={false}
                        date={currentEditRow?.effectiveDate}
                        fieldName="effectiveDate"
                        errorMessage={""}
                        onDateChange={portVM.onHeaderFieldChange}/>
            </div>
            , [PORT_CONSTANT.EFFECTIVE_DATE, currentEditRow?.effectiveDate, isAdd, isEdit, portVM.onHeaderFieldChange])
        
        const memoExpiryDate = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
                <DatePickerComponent
                        disabled={!(isAdd||isEdit)}
                        label={PORT_CONSTANT.EXPIRY_DATE}
                        width="260px"
                        optional={true}
                        date={currentEditRow?.expiryDate}
                        fieldName="expiryDate"
                        errorMessage={""}
                        onDateChange={portVM.onHeaderFieldChange}/>
            </div>
            , [PORT_CONSTANT.EXPIRY_DATE, currentEditRow?.expiryDate, isAdd, isEdit, portVM.onHeaderFieldChange])
                   
        
        const memoActiveInd = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
                <NbisCommonField 
                    fieldKey={"activeInd"} 
                    requiredFieldList={portRequiredFieldList} 
                    fieldLabel={PORT_CONSTANT.ACTIVE_IND}  
                    fieldType={FieldType.TEXT}  
                    isReadOnly={true} 
                    onFieldChange={()=>{}}/> 
                <HPHCheckbox 
                    label={""} 
                    checked={currentEditRow?.activeInd==='Y'} 
                    disabled={!(isAdd||isEdit)} 
                    onChange={(e) => portVM.onCheckboxChange(e.checked, "activeInd")} 
                    />
            </div>
            , [PORT_CONSTANT.ACTIVE_IND, portVM, currentEditRow?.activeInd, isAdd, isEdit])
        
            const handleEdit = useCallback(() => {
                portVM.onEditClick();
            }, [portVM]);
        
            const handleReset = useCallback(() => {
                portVM.onResetClick(isAdd);
            }, [portVM, isAdd]);

    return <>
        <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
            <Sidebarheader style={{ width: '100%' }}>
                {isLoading && <Loader Indicator="Spinner" size="Medium" />} 
                <Breadcrumb> 
                    <HPHBreadcrumb breadcrumbData={[{title: PortConstant.Title.TITLE}, 
                        { title:  PortConstant.Title.TITLE }]}onCurrentClick={()=>{}}> 
                    </HPHBreadcrumb> 
                </Breadcrumb> 
                <StyledAction>
                <>
                    {!(isAdd||isEdit)&&<IconButton fileName='Icon-pen' size='medium' disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Edit'} onClick={handleEdit} />}
                    {(isAdd||isEdit)&&<IconButton fileName="Icon-reset" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={() => handleReset()} />}
                    <IconButton fileName="Icon-cross" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement='bottom' toolTipText={'Close'} onClick={() => handClose()} />
                    {(isAdd||isEdit)&&<IconButton fileName="Icon-tick" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave()} />}
                </>
                </StyledAction>
            </Sidebarheader>

            <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'auto' }}> 
                <div className="flex-row-auto"> 
                    <div className={'flex-row-item'}> 
                        <div className={'im-flex-row'}> 
                            <div className={'flex-row-item-stretch'}> 
                            <SidebarTitle>
                                {isAdd&&"New Port"}
                                {!isAdd&&(currentEditRow.portCode)}
                            </SidebarTitle>
                                {<SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>} 
                            </div> 
                        </div>
                        <br/>
                        <CriteriaItemContainer>
                            {memoPortCode}
                            {memoPortName}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoEffectiveDate}
                            {memoExpiryDate}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoPortEncodeScheme}
                            {memoCountryCode}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoProvinceCode}
                            {memoGeographicalInd}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoActiveInd}
                        </CriteriaItemContainer>
                        </div>
                </div>
            </div>
        </StyledSidebar>
    </>
}

export default memo(PortEditPanel);
