import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { PortConstant } from "./PortConstant";

const PORT_CONSTANT = PortConstant.Table;
let dateFieldList:string[] = [];
let dateTimeFieldList:string[] = [];
export const INITIAL_PORT_COL_DEF: any[] = [

    {
        headerName: PORT_CONSTANT.PORT_CODE,
        field: "portCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 160,
        // pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        },
        {
            headerName: PORT_CONSTANT.EFFECTIVE_DATE,
            field: "effectiveDate",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 150,
            dataType : "date",
            },
            {
            headerName: PORT_CONSTANT.PORT_NAME,
            field: "portName",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 160,
            },
            {
            headerName: PORT_CONSTANT.PORT_ENCODE_SCHEME,
            field: "portEncodeScheme",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 200,
            },
            {
            headerName: PORT_CONSTANT.EXPIRY_DATE,
            field: "expiryDate",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 140,
            dataType : "date",
            },
            {
            headerName: PORT_CONSTANT.COUNTRY_CODE,
            field: "countryCode",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 150,
            },
            {
            headerName: PORT_CONSTANT.PROVINCE_CODE,
            field: "provinceCode",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 160,
            },
            {
            headerName: PORT_CONSTANT.GEOG_IND,
            field: "geogInd",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 120,
            },
            {
            headerName: PORT_CONSTANT.GEOG_IND_DESC,
            field: "geographicalIndDesc",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 160,
            },
            {
            headerName: PORT_CONSTANT.CREATION_TYPE,
            field: "createBy",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 150,
            },
        {
        headerName: PORT_CONSTANT.ACTIVE_IND,
        field: "activeInd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 130,
        },
        
].map((col, index) => {
    const cellRenderers:{[key:string]:((params:{[key:string]:string}, fieldName:string) => {})} = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data:any[]) => {
    const externalFnctions:{[key:string]:((fieldName:string, row:any) => {})} = {};

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}