import { SelectionChangedEvent } from "ag-grid-community";
import { PortEntity } from "domain/entity/Port/PortEntity";
import { INITIAL_PORT_COL_DEF } from "presentation/constant/Port/PortColumnDefinition";
import { usePortVM } from "presentation/hook/Port/usePortVM";
import { usePortTracked } from "presentation/store/Port/PortProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useMemo, useRef } from "react";
import { HPHTable } from "veronica-ui-component/dist/component/core";
const PortTablePanel = () => {
    const gridRef: any = useRef(null);
    const [portState] = usePortTracked();
    const {portList} = portState;
    const portVM = usePortVM();
    
    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();
        portVM.updateSelectedRows(portList,selectedRows);
      }, [portList, portVM])



    const handleRowDoubleClick = useCallback((entity: PortEntity) => {
        portVM.onRowDoubleClick(entity);
  }, [portVM])

    const memoPortTable = useMemo(() => {

        return (
        <HPHTable
            id='port-table'
            isNewColumnSetting={true}
            columns={INITIAL_PORT_COL_DEF}
            data={portList ?? []}
            showPaginator={false}
            editable={false}
            showAddIcon={false}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={false}
            isRowHighligted={true}
            onSelectionChanged={handleSelectionChange}
            onRowDoubleClick={(e: any, entity: PortEntity) => handleRowDoubleClick(entity)}
            gridHeight="customHeight"
            customHeight="calc(100vh - 125px)" 
            ref={gridRef}
        />
        );
    },[portList, handleRowDoubleClick, handleSelectionChange])

    return <><TableWrapper>
            {memoPortTable}
        </TableWrapper>
        </>;
}

export default memo(PortTablePanel);
