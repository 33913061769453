
export const PortConstant = {
    SEARCH_CRITERIA: "Search Criteria",
    Table:{
        PORT_CODE: "Port Code",
        EFFECTIVE_DATE: "Effective Date",
        PORT_NAME: "Port Name",
        PORT_ENCODE_SCHEME: "Port Encode Scheme",
        EXPIRY_DATE: "Expiry Date",
        COUNTRY_CODE: "Country Code",
        PROVINCE_CODE: "Province Code",
        GEOG_IND: "Geog Ind",
        GEOG_IND_DESC: "Geog Ind Desc",
        CREATION_TYPE: "Creation Type",
        ACTIVE_IND: "Active Ind.",
    },
    Title:{
        TITLE: "Port Setting",
    },
}
export const portRequiredFieldList: string[] = [
    'portCode',
    'portName',
    'effectiveDate',
    'countryCode',
    'geographicalInd',
    'activeInd',
];