import { usePortVM } from "presentation/hook/Port/usePortVM";
import { usePortTracked } from "presentation/store/Port/PortProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import PortSearchPanel from "./LeftPanel/PortSearchPanel";
import PortTitleBar from "./PortTitleBar";
import PortEditPanel from "./RightPanel/PortEditPanel";
import PortTablePanel from "./Table/PortTablePanel";


const PortMaintenance:React.FC = () => {
    const [portState] = usePortTracked();
    const [isLoading, setIsLoading] = useState(true);
    const portVM = usePortVM();
    const {isShowEditPanel,isShowSearchPanel} = portState;


    useEffect(() => {
        const initialScreen = async() => {   
            try {     
                const results = await Promise.allSettled([
                    portVM.searchPortList({}),
                    portVM.loadDropdownOption(),
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {
                        
                    } 
                })
            } catch(error) {
                setIsLoading(false);
            }
        }
        initialScreen().then((data) => {            
                setIsLoading(false)
        }).catch(error => {            
            setIsLoading(false)
        });
        
    }, [portVM])
    
    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;

    return <>
        <div className={`main-comp-wrapper${isShowSearchPanel ? '' : ' im-hide-side-form-draggable'}`}>
        
        {(isShowEditPanel)&&<>
            <PortEditPanel/></>}
        {!(isShowEditPanel)&&
        <><PortTitleBar/>
            <SliderPanel
                isOpen={true}
                draggable={false}
                leftSectionWidth={isShowSearchPanel?"20%":"0%"}
                rightSectionWidth={isShowSearchPanel?"80%":"100%"}
                leftChildren={<PortSearchPanel/>}
                rightChildren={<PortTablePanel/>}
                />
                </>}
        </div>
    </>
}

export default memo(PortMaintenance);